:root {
    --backgroundGradient: linear-gradient(90deg, rgba(252, 237, 39, 1) 24%, rgba(251, 156, 88, 1) 100%);
    --backgroundGradientBefore: linear-gradient(90deg, #fb9c58 47%, rgba(252, 131, 39, 1) 97%);
    --beforeOpacity: 0;
}

.hero {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: var(--backgroundGradient);
    transition: all 1000ms ease;
    opacity: 1;
    z-index: 3;

    @media only screen and (max-width: 768px) {
        height: auto;
        flex-direction: column-reverse;
        padding-bottom: 50px;
    }


    .text,
    .image {
        margin-top: 10px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .text {
        .description {
            width: 350px;
            aspect-ratio: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            backdrop-filter: blur(8px);
            background: rgb(236, 222, 209, 0.6);
            box-shadow: 3px 3px 4px 0px rgba(128, 0, 0, 0.26);
            position: relative;
            overflow: hidden;

            .text-gallery {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                padding-bottom: 0px;
                border-radius: 50%;

                p {
                    width: 270px;
                    height: 170px;
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                    letter-spacing: 0.1em;
                }

                h2 {
                    width: 350px;
                    margin: 0;
                    text-align: center;
                    font-size: 2em;
                    letter-spacing: 0em;
                    word-spacing: 0.16em;
                }
            }

            .desc-header {
                gap: 30px;
                transform: translate(-950px);
                transition: all 1000ms;
                transition-timing-function: cubic-bezier(.31, -0.36, .67, 1.41)
            }

            .desc-desc {
                gap: 100px;
                transform: translate(925px);
                transition: all 1000ms;
                transition-timing-function: cubic-bezier(.31, -0.36, .67, 1.41)
            }

        }

        .product-button {
            padding: 10px;
            margin-top: 20px;
            border-radius: 50px;
            font-size: 1.1em;
            font-weight: bold;
            border: none;
            background-color: rgb(142, 105, 122);
            color: #ECDED1;
            cursor: pointer;
            box-shadow: 3px 3px 0px 0px rgb(142, 105, 122, 0.41);
            transition: all 500ms;

            &:hover {
                transform: scale(1.1)
            }
        }

    }

    .image {
        margin-top: 50px;

        .image-gallery {
            width: 270px;
            height: 400px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 110px;
            filter: drop-shadow(11px 11px 14px rgb(0, 0, 0, 0.6));
            overflow: hidden;
            // transform: scale(0.7);

            .pics {
                width: 100%;
                // transform: scale(0.7);
                object-fit: fill;
                transition: all 1000ms;
                transition-timing-function: cubic-bezier(.31, -0.36, .67, 1.41);
            }
        }


        .img-buttons {
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            gap: 50px;
            align-items: center;
            justify-content: center;

            .button {
                width: 50px;
                aspect-ratio: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1em;
                cursor: pointer;
                user-select: none;
                transition: all 500ms;

                &:hover {
                    transform: scale(1.1)
                }
            }
        }

    }
}

.divider {
    height: 60%;
    border-left: 2px solid rgb(236, 222, 209, 0.7);

    @media only screen and (max-width: 768px) {
        // width: 30px;
        // height: 300px;
        transform: rotate(-90deg);
    }


}

.text,
.image,
.divider {
    z-index: 50;
}

.hero-background2 {
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: var(--backgroundGradientBefore);
    transition: opacity 500ms ease;
    opacity: var(--beforeOpacity);
    z-index: 2;
}


.about-products,
.about-us,
.contact {
    width: 100dvw;
    height: 100dvh;
    position: relative;
}

.products,
.contact {
    background: #ECDED1;
}

.products {
    width: 100dvw;
    height: auto;
    position: relative;


    @media only screen and (max-width: 768px) {
        height: auto;
    }
}

.about-products,
.about-us {
    background: #B8C7AC
}

.p-side-h {
    position: relative;
    padding-top: 50px;
    transform: translate(-50%) rotate(270deg);

    @media only screen and (max-width:768px) {
        padding-top: 10px;
        width: 100dvw;
        top: 0;
        left: 50%;
        text-align: center;
        transform: translate(-50%) rotate(0deg);
    }


    h2 {
        letter-spacing: 0em;
        word-spacing: 0.16em;
    }
}

.about-products {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.about-us {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2em;
    background-color: #7B9677;

    div {
        padding: 25px;

        h1 {
            margin: 0;
            text-shadow: 4px 2px 7px rgba(0, 0, 0, 0.274);
        }
    }
}

.contact {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 100px;
    padding-bottom: 60px;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.contact-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-left: 40px;
    font-size: 1.2em;

    .contactUs {
        margin-bottom: 20px;
    }

    .contact-address,
    .contact-phone,
    .contact-email {
        p {
            margin-bottom: -1px;
        }

        h1 {
            margin: 0px;
        }
    }
}

.message-box {
    width: 60%;
    height: 60%;
    display: flex;
    flex-direction: column;
    background-color: #7B9677;
    border-radius: 30px;

    p,
    .message-input {
        margin: 0;
        margin-top: 20px;
        padding-left: 20px;
        font-size: 1.6em;
        font-weight: bold;
    }

    .message-input {
        background: transparent;
        border: none;
    }

    input {
        outline: none
    }
}


.p-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 50px;

    @media only screen and (max-width: 767px) {
        gap: 50px;
        margin-left: 0px;
    }
}

.loadingPage {
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ecded18a;
    backdrop-filter: blur(8px);
    z-index: 100;

    transition: opacity 200ms linear;

    img {
        width: 30%;
        aspect-ratio: 1;
        object-fit: cover;
    }
}