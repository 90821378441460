.n {
    position: fixed;
    top: 0;
    width: 100dvw;
    display: flex;
    flex-direction: row;
    background: transparent;
    padding-top: 10px;
    justify-content: space-between;
    align-items: center;
    // backdrop-filter: blur(8px);
    transition: top 500ms, background 1000ms;
    z-index: 100;

    .menu-l {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 30px;
        font-weight: bold;
        font-size: 1.5em;

        div {
            padding: 5px;
            padding-left: 27px;
            padding-right: 27px;
            // transition: all 500ms;
            border-radius: 20px;
            letter-spacing: 0.12em;
            word-spacing: 0.16em;
            cursor: pointer;

            &::after {
                background: none repeat scroll 0 0 transparent;
                bottom: 0;
                content: "";
                display: block;
                height: 2px;
                left: 0;
                position: relative;
                background: black;
                transition: width 0.3s ease 0s, left 0.3s ease 0s;
                width: 0;
            }

            &:hover::after {
                width: 100%;
                left: 0;
            }

        }

        // div:hover {
        //     cursor: pointer;
        //     border: 2px solid black;
        // }
    }

    .menu-s {
        display: none;
    }


    .logo {
        width: 70px;
        aspect-ratio: 1;
        padding-left: 30px;

        img {
            width: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }


    @media only screen and (max-width: 768px) {

        .menu-l {
            display: none;
        }

        .menu-s {
            display: none;
            justify-content: center;
            align-items: center;
            padding-right: 30px;
            font-weight: bold;

            div {
                padding: 5px;
                padding-left: 35px;
                padding-right: 35px;
                // transition: all 500ms;
                border-radius: 20px;
            }

            div:hover {
                cursor: pointer;
                border: 1px solid black;
            }
        }
    }

}