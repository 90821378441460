.p-products {
    position: relative;
    width: 400px;
    height: 200px;
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    z-index: 4;
    margin-left: 30px;
    margin-bottom: 100px;

    @media only screen and (max-width: 768px) {
        height: 250px;
        margin-bottom: 30px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .p-products-front {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #f2e4d7;
        border-radius: inherit;
        box-shadow: 1.5px 1.5px 0px 0px rgb(142, 105, 122, 0.41);
        z-index: 3;

    }

    .p-image {
        width: 30%;
        z-index: inherit;
        transition: all 500ms;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .p-details {
        display: flex;
        flex-direction: column;
        z-index: inherit;
        width: 70%;

        .p-header {
            h2 {
                letter-spacing: 0em;
                margin-bottom: 0;
            }
        }

        .p-price {
            p {
                letter-spacing: 0.16em;
                margin-top: -3px;
            }
        }

        .p-ing {
            p {
                margin-top: 0px;
                font-style: italic;
            }
        }

        .p-desc {
            p {
                width: 95%;
                margin-top: 0px;
                letter-spacing: 0.05em;
            }

        }

        .p-button {
            width: 70%;
            margin-top: 10px;
            padding: 10px;
            border-radius: 10px;
            font-size: 1.1em;
            letter-spacing: 0.1em;
            color: #fff;
            background-color: rgb(142, 105, 122);
            cursor: pointer;
            transition: all 500ms;
            border: none;
            text-align: center;
        }
    }

    .p-products-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: white;
        opacity: 1;
        z-index: 1;
        transition: all 500ms;
        border-radius: inherit;
    }


    @media only screen and (min-width: 768px) {
        &:hover {
            .p-products-back {
                position: absolute;
                width: 200px;
                height: 300px;
                top: -50px;
                left: -75px;
                opacity: 1;
            }

            .p-image {
                position: relative;
                transform: rotate(-30deg) scale(1.5) translate(-30px, -25px);
            }
        }
    }
}